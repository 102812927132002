import React, { useState } from "react";
import {
  Button,
  Typography,
  FormControl,
  OutlinedInput,
  Drawer,
  IconButton,
  Box,
  InputLabel,
  Container,
} from "@mui/material";
import { useCustomer } from "../context/customerContext";
import CloseIcon from "@mui/icons-material/Close";
import Axios from "../config/Axios";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import CustomerConfirmModal from "./Modal";

function NewBeneficiary({
  onOpen,
  closeDrawer,
  customer,
  onSubmit,
  account_for,
}) {
  const { token } = useCustomer();
  const [state, setState] = useState({
    account_for,
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const router = useNavigate();

  const VerifyBank = async () => {
    try {
      setError(null);
      // Check if all required fields are filled and numbers are valid
      if (
        !state.beneficiaryName ||
        !state.accountNumber ||
        !state.bankName ||
        !state.routingNumber
      ) {
        return setError("All fields are required*");
      }

      if (isNaN(state.accountNumber)) {
        return setError("Account number must be numeric*");
      }

      if (isNaN(state.routingNumber)) {
        return setError("Routing number must be numeric*");
      }

      setLoading(true);
      await Axios(token).post(
        `/add-beneficiary/${customer?.customer_external_id}`,
        state,
      );
      setLoading(false);
      onSubmit(true);
      router(account_for === "deposit" ? "/add-funding-method" : "/withdrawal");
    } catch (e) {
      setLoading(false);
      setError(
        e?.response?.data?.response?.message ||
          e?.response?.data?.error ||
          e?.message,
      );
    }
  };

  const handleBankData = (e) => {
    const { id, value } = e.target;

    // Allow only numeric input for accountNumber and routingNumber
    if (id === "accountNumber" || id === "routingNumber") {
      if (!/^\d*$/.test(value)) {
        return setError(
          `${
            id === "accountNumber" ? "Account number" : "Routing number"
          } must be numeric`,
        );
      }
    }

    setState({ ...state, [id]: value });
    setError(null); // Clear any error when input is valid
  };

  const handleDrawerClose = () => {
    setState(null);
    if (closeDrawer) {
      closeDrawer(true);
    }
  };

  return (
    <>
      {loading && <Loader />}
      {error && (
        <CustomerConfirmModal
          type={`Error`}
          headLine={`Error`}
          showCancel={`Close`}
          message={error}
          isModalOpen={!!error}
          CloseOnClick={() => setError("")}
        />
      )}
      <Drawer anchor={"bottom"} open={onOpen} className="grayBg">
        <IconButton
          color="primary"
          onClick={handleDrawerClose}
          aria-label="close"
          size="small"
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Container sx={{ pb: 2, pt: 5 }}>
          <Typography variant="h4" gutterBottom>
            {account_for === "deposit" ? "Add Account" : "Add new Beneficiary"}
          </Typography>
          {account_for === "deposit" && (
            <Typography variant="body1" color="textSecondary" mb={1.5}>
              This may take up to 3 days to verify.
            </Typography>
          )}
          <Box pb={1.5}>
            <FormControl
              onChange={handleBankData}
              fullWidth
              variant="outlined"
              margin="dense"
              id={`beneficiaryName`}
            >
              <InputLabel htmlFor="beneficiaryName">
                Account Holder Name*
              </InputLabel>
              <OutlinedInput id="beneficiaryName" label="Account Holder Name" />
            </FormControl>
            <FormControl
              fullWidth
              variant="outlined"
              margin="dense"
              id={`accountNumber`}
              onChange={handleBankData}
            >
              <InputLabel htmlFor="accountNumber">Account no*</InputLabel>
              <OutlinedInput id="accountNumber" label="Account no" />
            </FormControl>
            <FormControl
              fullWidth
              variant="outlined"
              margin="dense"
              id={`bankName`}
              onChange={handleBankData}
            >
              <InputLabel htmlFor="bankName">Bank Name*</InputLabel>
              <OutlinedInput id="bankName" label="Bank Name" />
            </FormControl>
            <FormControl
              fullWidth
              variant="outlined"
              margin="dense"
              id={`routingNumber`}
              onChange={handleBankData}
            >
              <InputLabel htmlFor="routingNumber">Routing no*</InputLabel>
              <OutlinedInput id="routingNumber" label="Routing no" />
            </FormControl>
          </Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={VerifyBank}
            disabled={loading}
          >
            Add Beneficiary{" "}
          </Button>
        </Container>
      </Drawer>
    </>
  );
}

export default NewBeneficiary;
