/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { groupBy, toString } from "lodash";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Modal,
  Skeleton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import HeaderFull from "../components/headerFull";
import { useCustomer } from "../context/customerContext";
import Loader from "../components/Loader";
import { getActivitiesServices } from "../services/ActivitiesServices";
import PriceCard from "../components/priceCard";

import Axios from "../config/Axios";
import LayoutStyle from "../scss/base.module.scss";
import BottomNav from "../components/bottomNav";
import moment from "moment-timezone";
import { currencyFormat } from "../utils/Common";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import CallMadeOutlinedIcon from "@mui/icons-material/CallMadeOutlined";
import CallReceivedOutlinedIcon from "@mui/icons-material/CallReceivedOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import KeyboardCapslockIcon from "@mui/icons-material/KeyboardCapslock";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import pusherSub from "../utils/Pusher";
import Tour from "../components/projectGuide/tour";
import SendMoneyPendingModal from "../components/sendMoneyPendingModal";
import ListAltIcon from "@mui/icons-material/ListAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import SendMoneyReceivedModal from "../components/sendMoneyReceivedModal";
import SendMoneyExpiredModal from "../components/sendMoneyExpiredModal";
import Styles from "../scss/receipt.module.scss";
import GainLogoBoxWhite from "../svg/GainlogoBoxWhite";
import RequestMoneyPendingModal from "../components/requestMoneyPendingModal";

function DashboardTransation() {
  moment.tz.setDefault("America/Los_Angeles");
  const navigate = useNavigate();
  const { customer, token, load } = useCustomer();
  const { p2pId } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [trans, setTrans] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [failedModal, setFailedModal] = useState(false);
  const [runTour, setRunTour] = useState(false);
  const [selectedTab, setSelectedTab] = useState("All");
  const [p2pExtId, setP2pExtId] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [p2pDetails, setP2pDetails] = useState(null);
  const [isMoneyReceivedModal, setMoneyReceivedModal] = useState(false);
  const [isMoneyExpiredModal, setMoneyExpiredModal] = useState(false);
  const [requestIdCopied, setRequestIdCopied] = React.useState(false);
  const [sentToReceiptModal, setSentToReceiptModal] = useState(false);
  const [isReqMoneyPendingModal, setIsReqMoneyPendingModal] = useState(false);
  const hasEffectRun = useRef(false);
  useEffect(() => {
    if (customer?.show_guidance_first_time === true) {
      setRunTour(customer?.show_guidance_first_time);
    }
    if (!hasEffectRun.current && p2pId) {
      setLoading(true);
      Axios(token)
        .get(`/fetch-p2p-details/${p2pId}`)

        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    }
  }, [customer]);

  const fetchData = () => {
    if (customer?.customer_external_id && selectedTab === "Transactions") {
      setSkeletonLoader(true);
      getActivitiesServices(customer?.customer_external_id, token, 1).then(
        (res) => {
          setSkeletonLoader(false);
          const transactions = res?.trans;
          const groupByDate = groupBy(transactions, (item) => {
            if (moment(item?.created_at).isSame(moment(), "day")) {
              return "Today";
            } else if (
              moment(item?.created_at).isSame(
                moment().subtract(1, "day"),
                "day",
              )
            ) {
              return "Yesterday";
            } else {
              return moment(item?.created_at).format("YYYY-MM-DD");
            }
          });
          setTrans(groupByDate);
        },
      );
    } else if (customer?.customer_external_id && selectedTab === "Requests") {
      setSkeletonLoader(true);

      Axios(token)
        .get("/fetch-send-money-reqs", {
          params: {
            limit: 10,
          },
        })
        .then((res) => {
          setSkeletonLoader(false);

          const groupByDate = groupBy(res?.data?.response?.requests, (item) => {
            if (moment(item?.createdAt).isSame(moment(), "day")) {
              return "Today";
            } else if (
              moment(item?.createdAt).isSame(moment().subtract(1, "day"), "day")
            ) {
              return "Yesterday";
            } else {
              return moment(item?.createdAt).format("YYYY-MM-DD");
            }
          });
          setTrans(groupByDate);
        })
        .catch((e) => {
          setSkeletonLoader(false);

          console.log(e);
        });
    } else {
      setSkeletonLoader(true);

      let fullArray = [];
      Promise.all([
        getActivitiesServices(customer?.customer_external_id, token, 1),
        Axios(token).get("/fetch-send-money-reqs", {
          params: {
            limit: 10,
          },
        }),
      ])
        .then((res) => {
          setSkeletonLoader(false);

          fullArray = fullArray.concat(
            res[0]?.trans,
            res[1]?.data?.response?.requests,
          );
          const groupByDate = groupBy(fullArray, (item) => {
            const createdAt = item.createdAt;
            if (moment(createdAt).isSame(moment(), "day")) {
              return "Today";
            } else if (
              moment(createdAt).isSame(moment().subtract(1, "day"), "day")
            ) {
              return "Yesterday";
            } else {
              return moment(createdAt).format("YYYY-MM-DD");
            }
          });

          // Sort the groupByDate object
          const sortedGroupByDate = Object.keys(groupByDate)
            .sort((a, b) => {
              if (a === "Today") return -1;
              if (b === "Today") return 1;
              if (a === "Yesterday") return -1;
              if (b === "Yesterday") return 1;
              return new Date(b) - new Date(a);
            })
            .reduce((obj, key) => {
              obj[key] = groupByDate[key];
              return obj;
            }, {});

          setTrans(sortedGroupByDate);
        })
        .catch((e) => {
          setSkeletonLoader(false);

          console.log(e);
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, [load, token, selectedTab]);
  useEffect(() => {
    if (p2pExtId) {
      setLoading(true);

      Axios(token)
        .get(`/fetch-single-p2p-req/${p2pExtId}`)
        .then((res) => {
          setLoading(false);

          setP2pDetails(res?.data?.response);
        })
        .catch((e) => {
          setLoading(false);

          console.log(e);
        });
    }
  }, [p2pExtId]);
  const getTransactionForPusher = () => {
    getActivitiesServices(customer?.customer_external_id, token, 1).then(
      (res) => {
        const transactions = res?.trans;
        const groupByDate = groupBy(transactions, (item) => {
          if (moment(item?.created_at).isSame(moment(), "day")) {
            return "Today";
          } else if (
            moment(item?.created_at).isSame(moment().subtract(1, "day"), "day")
          ) {
            return "Yesterday";
          } else {
            return moment(item?.created_at).format("YYYY-MM-DD");
          }
        });
        setTrans(groupByDate);
      },
    );
  };
  useEffect(() => {
    pusherSub(
      toString(customer?.id),
      // "a",
      "p2p",
      () => {
        getTransactionForPusher();
      },
    );
  }, []);

  return (
    <div
    // style={{ position: "fixed", top: 0, bottom: 0, left: 0, right: 0 }}
    >
      <div
      // style={{ height: "100%", overflow: "auto" }}
      >
        {loading && <Loader show={loading} />}
        <Tour runTour={runTour} />
        <HeaderFull />
        <Box
          component={"main"}
          className={`${LayoutStyle.contentPart} ${LayoutStyle.bodyContainer}`}
        >
          <Container>
            <PriceCard
              data={customer}
              setFailedModal={setFailedModal}
              handleClick={() => setFailedModal(true)}
              arrow={false}
              accountNo={false}
              priceNav={true}
              accountTop={true}
            />
            <Box component={"div"} className={LayoutStyle.dashboardHeading}>
              <div className={"flex space-x-2 justify-between w-full py-2"}>
                <div
                  className={`border rounded-md border-blue-500 w-1/3 text-center py-1 cursor-pointer ${
                    selectedTab === "All" ? "text-white bg-[#00365B]" : ""
                  } `}
                  onClick={() => {
                    setTrans([]);
                    setSelectedTab("All");
                  }}
                >
                  <p className={"text-sm"}>All</p>
                </div>
                <div
                  className={`border rounded-md border-blue-500 w-1/3 text-center py-1 cursor-pointer ${
                    selectedTab === "Requests" ? "text-white bg-[#00365B]" : ""
                  } `}
                  onClick={() => {
                    setTrans([]);

                    setSelectedTab("Requests");
                  }}
                >
                  <p className={"text-sm"}>Requests</p>
                </div>
                <div
                  className={`border rounded-md border-blue-500 w-1/3 text-center py-1 cursor-pointer ${
                    selectedTab === "Transactions"
                      ? "text-white bg-[#00365B]"
                      : ""
                  } `}
                  onClick={() => {
                    setTrans([]);

                    setSelectedTab("Transactions");
                  }}
                >
                  <p className={"text-sm"}>Transactions</p>
                </div>
              </div>
              {/*<Typography variant="h3" mb={0}>*/}
              {/*  {trans ? (*/}
              {/*    <>All Activities</>*/}
              {/*  ) : (*/}
              {/*    <>*/}
              {/*      <Skeleton*/}
              {/*        animation="wave"*/}
              {/*        variant="rounded"*/}
              {/*        height={20}*/}
              {/*        width={"60%"}*/}
              {/*      />*/}
              {/*    </>*/}
              {/*  )}*/}
              {/*</Typography>*/}
            </Box>
            <ul className={LayoutStyle.transactionsList}>
              {skeletonLoader && (
                <>
                  <li>
                    <Typography variant="h5" fontWeight={500} mb={1.5}>
                      <Skeleton
                        animation="wave"
                        variant="rounded"
                        height={20}
                        width={150}
                      />
                    </Typography>
                    <Card elevation={3}>
                      <CardContent>
                        <Grid container direction={"row"} spacing={2}>
                          <Grid item xs={2}>
                            <Skeleton
                              variant="circular"
                              width={40}
                              height={40}
                            />
                          </Grid>
                          <Grid item xs={7}>
                            <Skeleton
                              animation="wave"
                              variant="rounded"
                              height={20}
                              width={150}
                              sx={{ mb: "0.5rem" }}
                            />
                            <Skeleton
                              animation="wave"
                              variant="rounded"
                              height={10}
                              width={50}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Skeleton
                              variant="text"
                              sx={{ fontSize: "1rem" }}
                            />
                          </Grid>
                        </Grid>
                        <Divider sx={{ my: 1, opacity: 0.5 }} />
                        <Grid container direction={"row"} spacing={2}>
                          <Grid item xs={2}>
                            <Skeleton
                              variant="circular"
                              width={40}
                              height={40}
                            />
                          </Grid>
                          <Grid item xs={7}>
                            <Skeleton
                              animation="wave"
                              variant="rounded"
                              height={20}
                              width={150}
                              sx={{ mb: "0.5rem" }}
                            />
                            <Skeleton
                              animation="wave"
                              variant="rounded"
                              height={10}
                              width={50}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Skeleton
                              variant="text"
                              sx={{ fontSize: "1rem" }}
                            />
                          </Grid>
                        </Grid>
                        <Divider sx={{ my: 1, opacity: 0.5 }} />
                        <Grid container direction={"row"} spacing={2}>
                          <Grid item xs={2}>
                            <Skeleton
                              variant="circular"
                              width={40}
                              height={40}
                            />
                          </Grid>
                          <Grid item xs={7}>
                            <Skeleton
                              animation="wave"
                              variant="rounded"
                              height={20}
                              width={150}
                              sx={{ mb: "0.5rem" }}
                            />
                            <Skeleton
                              animation="wave"
                              variant="rounded"
                              height={10}
                              width={50}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Skeleton
                              variant="text"
                              sx={{ fontSize: "1rem" }}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </li>
                  <li>
                    <Typography variant="h5" fontWeight={500} mb={1.5}>
                      <Skeleton
                        animation="wave"
                        variant="rounded"
                        height={20}
                        width={150}
                      />
                    </Typography>
                    <Card elevation={3}>
                      <CardContent>
                        <Grid container direction={"row"} spacing={2}>
                          <Grid item xs={2}>
                            <Skeleton
                              variant="circular"
                              width={40}
                              height={40}
                            />
                          </Grid>
                          <Grid item xs={7}>
                            <Skeleton
                              animation="wave"
                              variant="rounded"
                              height={20}
                              width={150}
                              sx={{ mb: "0.5rem" }}
                            />
                            <Skeleton
                              animation="wave"
                              variant="rounded"
                              height={10}
                              width={50}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Skeleton
                              variant="text"
                              sx={{ fontSize: "1rem" }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container direction={"row"} spacing={2}>
                          <Grid item xs={2}>
                            <Skeleton
                              variant="circular"
                              width={40}
                              height={40}
                            />
                          </Grid>
                          <Grid item xs={7}>
                            <Skeleton
                              animation="wave"
                              variant="rounded"
                              height={20}
                              width={150}
                              sx={{ mb: "0.5rem" }}
                            />
                            <Skeleton
                              animation="wave"
                              variant="rounded"
                              height={10}
                              width={50}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Skeleton
                              variant="text"
                              sx={{ fontSize: "1rem" }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container direction={"row"} spacing={2}>
                          <Grid item xs={2}>
                            <Skeleton
                              variant="circular"
                              width={40}
                              height={40}
                            />
                          </Grid>
                          <Grid item xs={7}>
                            <Skeleton
                              animation="wave"
                              variant="rounded"
                              height={20}
                              width={150}
                              sx={{ mb: "0.5rem" }}
                            />
                            <Skeleton
                              animation="wave"
                              variant="rounded"
                              height={10}
                              width={50}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Skeleton
                              variant="text"
                              sx={{ fontSize: "1rem" }}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </li>
                </>
              )}
              {Object.keys(trans).length > 0 ? (
                <>
                  {Object.keys(trans)?.map((key, index) => (
                    <li key={index + 1}>
                      <Typography variant="h5" mb={1.5}>
                        {key}
                      </Typography>
                      <>
                        <Card elevation={3}>
                          <CardContent>
                            {trans[key]?.map((item, index) => (
                              <div key={index}>
                                <Grid
                                  container
                                  direction={"row"}
                                  spacing={2}
                                  key={index}
                                  onClick={() => {
                                    setP2pExtId(
                                      item?.friendsandfamily_external_id,
                                    );

                                    if (
                                      item?.type === "SEND_MONEY" &&
                                      item?.payment_status === "RECEIVED"
                                    ) {
                                      setMoneyReceivedModal(true);
                                    }
                                    if (
                                      item?.type === "SEND_MONEY" &&
                                      item?.payment_status === "PENDING"
                                    ) {
                                      setModalOpen(true);
                                    }
                                    if (
                                      item?.type === "REQUEST" &&
                                      item?.payment_status === "PENDING"
                                    ) {
                                      setIsReqMoneyPendingModal(true);
                                    }
                                    if (
                                      item?.type === "SEND_MONEY" &&
                                      item?.payment_status === "EXPIRED"
                                    ) {
                                      setMoneyExpiredModal(true);
                                    }
                                    // if (item?.transactionFor === "SEND_MONEY") {
                                    //   setMoneyReceivedModal(true);
                                    // }
                                  }}
                                >
                                  <Grid item xs={2}>
                                    <Avatar
                                      color="primary"
                                      sx={{ padding: "0.625rem" }}
                                    >
                                      {(item?.transactionFor === "LOAD_FUND" ||
                                        item?.transactionFor === "TIPS" ||
                                        item?.transactionFor ===
                                          "refund_to_card" ||
                                        item?.transactionFor ===
                                          "refund_to_wallet") && (
                                        <PaymentsOutlinedIcon color="white" />
                                      )}

                                      {item?.transactionFor ===
                                        "pay_for_goods_and_services" &&
                                        item?.store?.account_number && (
                                          <ReceiptLongOutlinedIcon color="white" />
                                        )}

                                      {(item?.transactionFor === "SEND_MONEY" ||
                                        item?.transactionFor ===
                                          "returned_to_customer" ||
                                        item?.type === "SEND_MONEY" ||
                                        item?.type === "REQUEST") && (
                                        <CallMadeOutlinedIcon color="white" />
                                      )}
                                      {item?.transactionFor ===
                                        "RECEIVE_MONEY" && (
                                        <CallReceivedOutlinedIcon color="white" />
                                      )}
                                      {item?.transactionFor ===
                                        "withdrawal_request" && (
                                        <KeyboardCapslockIcon color="white" />
                                      )}
                                      {item?.transactionFor ===
                                        "deposit_request" && (
                                        <AssuredWorkloadIcon color="white" />
                                      )}
                                    </Avatar>
                                  </Grid>
                                  <Grid item xs={7}>
                                    <Typography variant="h5" mb={0}>
                                      <>
                                        {item?.transactionFor ===
                                        "LOAD_FUND" ? (
                                          `Card Charged`
                                        ) : (
                                          <>{item?.transaction_type}</>
                                        )}
                                      </>
                                    </Typography>
                                    {item?.customer_name && (
                                      <>
                                        <Typography variant="h4" gutterBottom>
                                          {item?.transactionFor ===
                                            "SEND_MONEY" && `Sent to`}
                                          {item?.transactionFor ===
                                            "RECEIVE_MONEY" && `Received from`}
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          mb={0}
                                          textTransform={"capitalize"}
                                        >
                                          {item?.customer_name}
                                        </Typography>
                                      </>
                                    )}
                                    {item?.type === "SEND_MONEY" && (
                                      <>
                                        <Typography variant="h4" gutterBottom>
                                          Funds Transfer Initiated
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          mb={0}
                                          textTransform={"capitalize"}
                                        >
                                          {item?.payment_status === "RECEIVED"
                                            ? item?.receiver?.fullName
                                            : item?.receiver_name}
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          mb={0}
                                          mt={0.5}
                                        >
                                          {moment(item?.createdAt).format(
                                            "lll",
                                          )}
                                        </Typography>
                                      </>
                                    )}
                                    {item?.type === "REQUEST" && (
                                      <>
                                        <Typography variant="h4" gutterBottom>
                                          Funds Transfer Requested
                                        </Typography>
                                        {item?.payment_status === "PAID" && (
                                          <Typography
                                            variant="h6"
                                            mb={0}
                                            textTransform={"capitalize"}
                                          >
                                            {/*{item?.payment_status === "RECEIVED"*/}
                                            {/*  ? item?.sender?.fullName*/}
                                            {/*  : item?.sender?.fullName}*/}
                                            {/*{item?.payment_status === "PENDING"*/}
                                            {/*  ? item?.sender_name*/}
                                            {/*  : item?.sender_name}*/}
                                            {item?.sender?.fullName}
                                          </Typography>
                                        )}
                                        {item?.payment_status === "PENDING" && (
                                          <Typography
                                            variant="h6"
                                            mb={0}
                                            textTransform={"capitalize"}
                                          >
                                            {/*{item?.payment_status === "RECEIVED"*/}
                                            {/*  ? item?.sender?.fullName*/}
                                            {/*  : item?.sender?.fullName}*/}
                                            {/*{item?.payment_status === "PENDING"*/}
                                            {/*  ? item?.sender_name*/}
                                            {/*  : item?.sender_name}*/}
                                            {item?.sender_name}
                                          </Typography>
                                        )}
                                        {item?.payment_status ===
                                          "REJECTED" && (
                                          <Typography
                                            variant="h6"
                                            mb={0}
                                            textTransform={"capitalize"}
                                          >
                                            {/*{item?.payment_status === "RECEIVED"*/}
                                            {/*  ? item?.sender?.fullName*/}
                                            {/*  : item?.sender?.fullName}*/}
                                            {/*{item?.payment_status === "PENDING"*/}
                                            {/*  ? item?.sender_name*/}
                                            {/*  : item?.sender_name}*/}
                                            {item?.sender_name}
                                          </Typography>
                                        )}

                                        <Typography
                                          variant="body2"
                                          mb={0}
                                          mt={0.5}
                                        >
                                          {moment(item?.createdAt).format(
                                            "lll",
                                          )}
                                        </Typography>
                                      </>
                                    )}
                                    {/*{item?.type === "SEND_MONEY" && (*/}
                                    {/*  <Typography*/}
                                    {/*    variant="body2"*/}
                                    {/*    mb={0}*/}
                                    {/*    mt={0.5}*/}
                                    {/*  >*/}
                                    {/*    <Typography*/}
                                    {/*      variant="body2"*/}
                                    {/*      mb={0}*/}
                                    {/*      mt={0.5}*/}
                                    {/*    >*/}
                                    {/*      {moment(item?.created_at).format(*/}
                                    {/*        "lll",*/}
                                    {/*      )}*/}
                                    {/*    </Typography>*/}
                                    {/*  </Typography>*/}
                                    {/*)}*/}
                                    <Typography variant="body2" mb={0} mt={0.5}>
                                      {item?.transactionFor === "LOAD_FUND" ||
                                      item?.transactionFor ===
                                        "withdrawal_request" ||
                                      item?.transactionFor ===
                                        "deposit_request" ||
                                      item?.transactionFor ===
                                        "RECEIVE_MONEY" ? (
                                        <Typography
                                          variant="body2"
                                          mb={0}
                                          mt={0.5}
                                        >
                                          {moment(item?.created_at).format(
                                            "lll",
                                          )}
                                        </Typography>
                                      ) : (
                                        <>
                                          {item?.store?.city
                                            ? item?.store?.city + " |"
                                            : ""}
                                          {item?.store?.state || ""}
                                        </>
                                      )}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3}>
                                    {item?.transactionFor ===
                                      "pay_for_goods_and_services" ||
                                    item?.transactionFor ===
                                      "withdrawal_request" ||
                                    item?.transactionFor ===
                                      "deposit_request" ||
                                    item?.transactionFor ===
                                      "returned_to_customer" ? (
                                      <Typography
                                        textAlign={"right"}
                                        variant="h5"
                                        gutterBottom
                                        color={"error"}
                                      >
                                        {currencyFormat(item?.amount)}
                                      </Typography>
                                    ) : (
                                      <>
                                        <Typography
                                          textAlign={"right"}
                                          variant="h5"
                                          gutterBottom
                                          sx={{ color: "green" }}
                                        >
                                          {currencyFormat(
                                            item?.total_amount || item?.amount,
                                          )}
                                        </Typography>
                                        {item?.type && (
                                          <div
                                            className={`rounded-lg ${
                                              item?.payment_status === "PENDING"
                                                ? "bg-yellow-200"
                                                : item?.payment_status ===
                                                  "EXPIRED"
                                                ? "bg-red-200"
                                                : "bg-green-200"
                                            }`}
                                          >
                                            <p
                                              className={`text-xs text-center ${
                                                item?.payment_status ===
                                                "PENDING"
                                                  ? "text-yellow-600"
                                                  : item?.payment_status ===
                                                    "EXPIRED"
                                                  ? "text-red-600"
                                                  : "text-green-600"
                                              }`}
                                            >
                                              {item?.payment_status}
                                            </p>
                                          </div>
                                        )}
                                      </>
                                    )}
                                    {item?.funds != null &&
                                      item?.transactionFor === "LOAD_FUND" && (
                                        <Typography
                                          variant="body2"
                                          mb={0}
                                          className={`!text-right`}
                                        >
                                          <Link
                                            underline="none"
                                            style={{
                                              color: "green",
                                              whiteSpace: "nowrap",
                                            }}
                                            to={`/receipt/${item?.funds}`}
                                          >
                                            {" "}
                                            View Receipt{" "}
                                          </Link>
                                        </Typography>
                                      )}
                                    {(item?.transactionFor ===
                                      "withdrawal_request" ||
                                      item?.transactionFor ===
                                        "deposit_request" ||
                                      item?.transactionFor ===
                                        "pay_for_goods_and_services") && (
                                      <Typography
                                        variant="body2"
                                        mb={0}
                                        mt={0.5}
                                        textAlign={"right"}
                                      >
                                        {item?.status}
                                      </Typography>
                                    )}
                                  </Grid>
                                </Grid>
                                {trans[key].length > 1 && (
                                  <Divider sx={{ my: 1, opacity: 0.5 }} />
                                )}
                              </div>
                            ))}
                          </CardContent>
                        </Card>
                      </>
                    </li>
                  ))}
                </>
              ) : (
                <div className={"mt-10"}>
                  <svg
                    id="fi_6134116"
                    className={`w-14 mx-auto mb-4 block`}
                    viewBox="0 0 511.985 511.985"
                    width="512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <path d="m344.162 0c-92.537 0-167.822 75.285-167.822 167.822 0 43.577 16.699 83.323 44.028 113.188l-30.978 30.978-.365-.365c-4.444-4.444-10.354-6.893-16.64-6.893s-12.195 2.448-16.64 6.893l-146.52 146.521c-12.3 12.301-12.3 32.315 0 44.616 6.15 6.15 14.229 9.226 22.308 9.226s16.158-3.075 22.308-9.226l146.52-146.52c4.445-4.445 6.893-10.354 6.893-16.64s-2.448-12.195-6.893-16.64l-.365-.365 30.978-30.978c29.865 27.329 69.612 44.028 113.188 44.028 92.538 0 167.823-75.285 167.823-167.823 0-92.537-75.285-167.822-167.823-167.822zm-300.927 492.153c-6.452 6.452-16.951 6.452-23.403 0s-6.452-16.951 0-23.403l123.073-123.073 23.403 23.403zm146.52-146.52-12.841 12.841-23.403-23.403 12.841-12.841c1.611-1.611 3.754-2.499 6.033-2.499s4.422.888 6.033 2.499l11.336 11.337c1.612 1.612 2.499 3.754 2.499 6.033.001 2.279-.886 4.421-2.498 6.033zm154.407-24.987c-84.267 0-152.822-68.556-152.822-152.823s68.556-152.823 152.822-152.823 152.823 68.556 152.823 152.822c0 84.267-68.556 152.824-152.823 152.824z"></path>
                      <path d="m440.158 71.827c-48.271-48.271-125.3-53.072-179.172-11.17-3.27 2.543-3.859 7.255-1.315 10.524 2.542 3.27 7.254 3.859 10.524 1.315 47.916-37.269 116.424-32.996 159.357 9.937 42.933 42.934 47.205 111.442 9.937 159.356-2.543 3.27-1.954 7.982 1.315 10.525 1.369 1.064 2.99 1.58 4.599 1.58 2.235 0 4.447-.995 5.925-2.896 41.903-53.872 37.102-130.899-11.17-179.171z"></path>
                      <path d="m418.131 263.147c-21.84 16.988-47.954 25.345-73.979 25.344-31.071-.001-62.014-11.916-85.377-35.28-42.933-42.933-47.205-111.442-9.937-159.356 2.543-3.27 1.954-7.982-1.315-10.525-3.271-2.543-7.982-1.954-10.524 1.315-41.904 53.874-37.102 130.901 11.17 179.172 26.271 26.272 61.053 39.667 95.992 39.666 29.257-.001 58.627-9.398 83.18-28.496 3.27-2.543 3.858-7.255 1.315-10.525-2.544-3.268-7.257-3.858-10.525-1.315z"></path>
                      <path d="m282.746 165.109c1.464 1.465 3.384 2.197 5.303 2.197s3.839-.732 5.303-2.197l10.729-10.729 10.729 10.729c1.464 1.465 3.384 2.197 5.303 2.197s3.839-.732 5.303-2.197c2.929-2.929 2.929-7.677 0-10.606l-10.729-10.729 10.729-10.729c2.929-2.929 2.929-7.677 0-10.606s-7.678-2.929-10.606 0l-10.729 10.729-10.729-10.729c-2.929-2.929-7.678-2.929-10.606 0-2.929 2.929-2.929 7.678 0 10.606l10.729 10.729-10.729 10.729c-2.929 2.929-2.929 7.678 0 10.606z"></path>
                      <path d="m362.908 165.109c1.464 1.465 3.384 2.197 5.303 2.197s3.839-.732 5.303-2.197l10.729-10.729 10.729 10.729c1.464 1.465 3.384 2.197 5.303 2.197s3.839-.732 5.303-2.197c2.929-2.929 2.929-7.678 0-10.606l-10.729-10.729 10.729-10.729c2.929-2.929 2.929-7.677 0-10.606s-7.678-2.929-10.606 0l-10.729 10.729-10.729-10.729c-2.929-2.929-7.678-2.929-10.606 0-2.929 2.929-2.929 7.678 0 10.606l10.729 10.729-10.729 10.729c-2.93 2.929-2.93 7.678 0 10.606z"></path>
                      <path d="m298.774 218.637c-2.926 2.932-2.922 7.681.009 10.607 1.464 1.461 3.381 2.192 5.299 2.192 1.921 0 3.843-.734 5.308-2.201 8.774-8.79 21.448-13.831 34.772-13.831s25.999 5.041 34.773 13.831c2.926 2.932 7.675 2.936 10.606.009 2.931-2.926 2.936-7.675.009-10.606-11.567-11.588-28.111-18.233-45.389-18.233-17.276-.002-33.82 6.644-45.387 18.232z"></path>
                    </g>
                  </svg>
                  <p className={"text-center"}>No fund requests found</p>
                  <div className={"flex justify-center"}>
                    <button
                      className={
                        "border p-3 bg-blue-950 text-white rounded-md mt-3"
                      }
                      onClick={() => navigate("/transfer-money")}
                    >
                      Send Money
                    </button>
                  </div>
                </div>
              )}
            </ul>
            <RequestMoneyPendingModal
              isModalOpen={isReqMoneyPendingModal}
              setModalOpen={setIsReqMoneyPendingModal}
              p2pDetails={p2pDetails}
              isLoading={loading}
              requestIdCopied={requestIdCopied}
              setRequestIdCopied={setRequestIdCopied}
              callBack={fetchData}
            />
            <SendMoneyPendingModal
              isModalOpen={isModalOpen}
              setModalOpen={setModalOpen}
              p2pDetails={p2pDetails}
              isLoading={loading}
              requestIdCopied={requestIdCopied}
              setRequestIdCopied={setRequestIdCopied}
              callBack={fetchData}
            />
            <SendMoneyReceivedModal
              isMoneyReceivedModal={isMoneyReceivedModal}
              setMoneyReceivedModal={setMoneyReceivedModal}
              p2pDetails={p2pDetails}
              isLoading={loading}
              requestIdCopied={requestIdCopied}
              setRequestIdCopied={setRequestIdCopied}
            />
            <SendMoneyExpiredModal
              isMoneyExpiredModal={isMoneyExpiredModal}
              setMoneyExpiredModal={setMoneyExpiredModal}
              p2pDetails={p2pDetails}
              isLoading={loading}
              requestIdCopied={requestIdCopied}
              setRequestIdCopied={setRequestIdCopied}
            />
            {/*<Dialog open={sentToReceiptModal}>*/}
            {/*  <DialogContent sx={{ padding: "0" }}></DialogContent>*/}
            {/*</Dialog>*/}
          </Container>
        </Box>
        <BottomNav payRound={true} />
      </div>
    </div>
  );
}

export default DashboardTransation;
