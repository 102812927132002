import React from "react";
import { useCustomer } from "../context/customerContext";
import Axios from "../config/Axios";
import { currencyFormat } from "../utils/Common";
import { Dialog, DialogContent } from "@mui/material";
import Loader from "./Loader";
import CloseIcon from "@mui/icons-material/Close";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

function RequestMoneyPendingModal({
  isModalOpen,
  setModalOpen,
  p2pDetails,
  isLoading,
  requestIdCopied,
  setRequestIdCopied,
  callBack,
}) {
  const { token } = useCustomer();
  const [loading, setLoading] = React.useState(false);
  console.log(p2pDetails);
  const invalidateRequest = () => {
    setLoading(true);
    Axios(token)
      .get(`/invalidate-req-money/${p2pDetails?.friendsandfamily_external_id}`)
      .then((res) => {
        setLoading(false);
        callBack();
        setModalOpen(false);
        console.log(res);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const copyRequestId = () => {
    navigator.clipboard.writeText(p2pDetails?.friendsandfamily_external_id);
    setRequestIdCopied(true);
    setTimeout(() => setRequestIdCopied(false), 1000);
  };

  const message = `Hi, ${
    p2pDetails?.receiver_name
  }, I've sent you ${currencyFormat(p2pDetails?.amount)} through Gain Funds.
Please click the link below to receive it ${window.location.origin}/p2p/${
    p2pDetails?.friendsandfamily_external_id
  }`;

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator
          .share({
            title: `Payment Requested from: ${p2pDetails?.sender_name}`,
            text: message,
          })
          .then(() => console.log("Successful share"))
          .catch((error) => console.log("Error sharing:", error));
      } else {
        // Fallback for browsers that do not support the Web Share API
        // alert('Web Share API is not supported in this browser');
      }
    } catch (error) {
      console.error("Error sharing:", error.message);
    }
  };
  return (
    <>
      <>
        <Dialog open={isModalOpen}>
          {loading && <Loader show={loading} />}
          <DialogContent>
            <div className="flex justify-between items-center">
              <p className="text-sm">Request pending</p>
              <button
                onClick={() => {
                  // setRequestIdCopied("");
                  setModalOpen(false);
                }}
                className="bg-gray-200 rounded-full p-1 hover:bg-gray-300 transition-colors flex items-center justify-center"
                aria-label="Close"
              >
                <CloseIcon
                  sx={{
                    fontSize: "1rem",
                    color: "rgb(75, 85, 99)", // Tailwind's gray-600
                  }}
                />
              </button>
            </div>

            {isLoading ? (
              <div className="flex flex-col items-center justify-center h-48">
                <div className="animate-pulse bg-gray-200 rounded-full h-12 w-12 mb-4" />
                <div className="animate-pulse bg-gray-200 h-4 w-40 mb-2" />
                <div className="animate-pulse bg-gray-200 h-4 w-24 mb-2" />
                <div className="animate-pulse bg-gray-200 h-4 w-32 mb-2" />
                <div className="animate-pulse bg-gray-200 h-4 w-28 mb-2" />
              </div>
            ) : (
              <>
                <div
                  className={
                    "flex justify-between items-center mt-3 bg-gray-200 rounded-md p-2"
                  }
                >
                  <div className={"flex space-x-2 items-center"}>
                    <AccountBoxIcon
                      sx={{
                        fontSize: "2.5rem",
                      }}
                    />
                    <div className={"flex-col "}>
                      <p className={"text-sm font-semibold"}>
                        {p2pDetails?.sender_name || "N/A"}
                      </p>
                    </div>
                  </div>
                  <div>
                    <p>${p2pDetails?.amount}</p>
                  </div>
                </div>
                <div className={"flex mt-3 justify-between items-center"}>
                  <div className={"flex space-x-3 items-center"}>
                    <ListAltIcon />
                    <p className={"text-sm"}>Request details</p>
                  </div>
                </div>
                <div className={"flex mt-3 justify-between items-center"}>
                  <div className={"flex-col"}>
                    <p className={"text-xs"}>Request ID</p>
                    <p className={"text-xs"}>
                      {p2pDetails?.friendsandfamily_external_id}
                      {requestIdCopied && (
                        <p className="text-green-500">Copied!</p>
                      )}
                    </p>
                  </div>
                  <div onClick={copyRequestId}>
                    <ContentCopyIcon sx={{ fontSize: "1rem" }} />
                  </div>
                </div>
                <div className={"flex pb-2 w-full space-x-4 mt-3"}>
                  <button
                    className={
                      "bg-blue-950 text-white p-2 rounded-[0.60rem] w-1/2"
                    }
                    onClick={handleShare}
                  >
                    Share
                  </button>
                  <button
                    className={
                      "bg-gray-500 text-white p-2 rounded-[0.60rem] w-1/2"
                    }
                    onClick={invalidateRequest}
                  >
                    Invalidate
                  </button>
                </div>
              </>
            )}
          </DialogContent>
        </Dialog>
      </>
    </>
  );
}

export default RequestMoneyPendingModal;
